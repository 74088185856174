import {sendGTMEvent} from "@next/third-parties/google";
import axios from "axios";

export const Res_btnClick = async (
    listing_id: number,
    Listing_title: string,
    userId?: string,
    shop_id?: number,
    shop_name?: string,
    price?: number,
    url?: string
) => {

    let is_guest = userId ? false : true;

    const userEventObject = {
        event_name: "reserve",
        fk_user_id: userId,
        listing_id: listing_id,
        is_guest: is_guest,
    };

    sendGTMEvent({


        event: `dry_conversion`,
        value: price,
        currency: 'PKR',
        items: [
            {
                item_id: listing_id,
                item_name: Listing_title,
                price: price, 
                shop_id: shop_id,
                shop_name: shop_name,               
            }
        ],
        url: url,


        
    })

    await axios
        .post(process.env.NEXT_PUBLIC_MARKETPLACE_API_URL + `/user-events`, JSON.stringify(userEventObject), {
            headers: {
                "Content-Type": "application/json",
            }
        })
        .then((response) => {

        })
        .catch((error) => {
            console.log(error);
        });
};
export const Phone_BtnClick = async (shop_id: number, userId?: string, url?:string, value?:string, ) => {
    let is_guest = userId ? false : true;

    const userEventObject = {
        event_name: "contact-phone",
        shop_id: shop_id,
        fk_user_id: userId,
        is_guest: is_guest,
    };

    sendGTMEvent({
        event: `lead`,
        value: value,
        leadType: 'phone',
        url:url,


    });
    await axios
        .post(process.env.NEXT_PUBLIC_MARKETPLACE_API_URL + `/user-events`, JSON.stringify(userEventObject), {
            headers: {
                "Content-Type": "application/json",
            }
        })
        .then((response) => {
        })
        .catch((error) => {
            console.log(error);
        });
}

export const wa_BtnClick = async (shop_id: number, userId?: string, phone_number?: string,url?:string,value?:string) => {
    let is_guest = userId ? false : true;
    const userEventObject = {
        event_name: "contact-whatsapp",
        shop_id: shop_id,
        fk_user_id: userId,
        is_guest: is_guest,
    };
    sendGTMEvent({
        event: `lead`,
        value: value,
        leadType: 'whatsapp',
        url:url,

    });
    await axios
        .post(process.env.NEXT_PUBLIC_MARKETPLACE_API_URL + `/user-events`, JSON.stringify(userEventObject), {
            headers: {
                "Content-Type": "application/json",
            }
        })
        .then((response) => {
        })
        .catch((error) => {
            console.log(error);
        });

}


export const store_visit = (shop_id: number, userId?: string, location: string, url: string, shop_name: string) => {
    let is_guest = userId ? false : true;
    const userEventObject = {
        event_name: "store-visit",
        shop_id: shop_id,
        fk_user_id: userId,
        is_guest: is_guest,
    };

    sendGTMEvent({
        event: `Visit-Store`,
        value: shop_name,
        action: "cilck",
        location: location,
        shopId: shop_id,
        url: url
    });
    axios
        .post(process.env.NEXT_PUBLIC_MARKETPLACE_API_URL + `/user-events`, JSON.stringify(userEventObject), {
            headers: {
                "Content-Type": "application/json",
            }
        })
        .then((response) => {
        })
        .catch((error) => {
            console.log(error);
        });
}

export const model_visit = (model_id: number, userId?: string, category?: string, title: string, url: string) => {
    let is_guest = userId ? false : true;
    const userEventObject = {
        event_name: "model-visit",
        model_id: model_id,
        fk_user_id: userId,
        is_guest: is_guest,
    };
    sendGTMEvent({
        event: `visit_model`,
        value: model_id,
        action: "cilck",
        location: "Specs PLP",
        category: category,
        Model_title: title,
        url: url

    });
    axios
        .post(process.env.NEXT_PUBLIC_MARKETPLACE_API_URL + `/user-events`, JSON.stringify(userEventObject), {
            headers: {
                "Content-Type": "application/json",
            }
        })
        .then((response) => {
        })
        .catch((error) => {
            console.log(error);
        });
}

export const listing_visit = (listing_id: number, userId?: string, location?: string, title?: string, url?: string) => {
    let is_guest = userId ? false : true;
    const userEventObject = {
        event_name: "listing-visit",
        listing_id: listing_id,
        fk_user_id: userId,
        is_guest: is_guest,
    };


    sendGTMEvent({
        event: `visit_listing`,
        value: listing_id,
        action: "cilck",
        location: location,

        Listing_title: title,
        url: url

    });
    axios
        .post(process.env.NEXT_PUBLIC_MARKETPLACE_API_URL + `/user-events`, JSON.stringify(userEventObject), {
            headers: {
                "Content-Type": "application/json",
            }
        })
        .then((response) => {
        })
        .catch((error) => {
            console.log(error);
        });
}


export const wishlist_add = (title: string, url?: string, listing_id?:number,price?: number) => {
    
    sendGTMEvent({
        event: `add_to_wishlist`,
        value: price,
        currency: 'PKR',
        items: [
            {
                item_id: listing_id,
                item_name: title,
                price: price,                
            }
        ],
        url: url,
        
    })
}

export const Searches = (search: string, location: string, url: string) => {
    sendGTMEvent({
        event: `Searches`,
        value: search,
        action: "cilck",
        location: location,

        search: search,
        url: url
    })

}



